import React from "react"
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
{
  prismic {
    allMetadatas {
      edges {
        node {
          titre_404
          description_404
        }
      }
    }
  }
}
`
const IndexPage = ({ data }) => {
  const doc = data.prismic.allMetadatas.edges.slice(0,1).pop().node;
  return (
  <Layout>
    <SEO title="404: not found" />

    <section class="content-center main-content">
      {RichText.render(doc.titre_404)}
      {RichText.render(doc.description_404)}
    </section>
  </Layout>
  )
}

export default IndexPage
